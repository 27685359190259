<template>
  <div>
    <p>List user</p>
  </div>
</template>

<script>
    export default {
        name: "List"
    }
</script>

<style scoped>

</style>
